<template>
  <v-container fluid>
    <v-btn
      :to="{name: 'admin:application_history', params: {uuid: $route.params.uuid}}"
    >
      <v-icon left>
        history
      </v-icon>
      Ver Historial de Postulaciones
    </v-btn>
    <v-btn :to="{name: 'admin:mmdigital_evaluation_history', params: {uuid: $route.params.uuid}}">
      <v-icon left>
        mdi-clipboard-text-outline
      </v-icon>
      Historial de Evaluaciones
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="applications"
      hide-actions
      :pagination.sync="pagination"
    >
      <template
        slot="headers"
      >
        <tr>
          <th
            v-for="header in headers"
            :key="header.text"
            align="left"
            style="font-size: small; font-weight: bold"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template
        slot="items"
        slot-scope="props"
      >
        <td>{{ props.item.offer.company }}</td>
        <td>{{ props.item.offer.position }}</td>
        <td>{{ props.item.offer.admin }}</td>
        <td>{{ states[props.item.recent_status.status] }}</td>
        <td>
          {{ props.item.offer.state }}
          <v-btn
            icon
            :to="{name: 'admin:offer_applicants',
                  params: {uuid: props.item.offer.uuid }}"
          >
            <v-icon
              center
              small
            >
              fas fa-external-link-alt
            </v-icon>
          </v-btn>
        </td>
        <td>
          <v-btn
            :to="{name: 'admin:application_detail',
                  params: {ohc_uuid: props.item.uuid} }"
          >
            <v-icon left>
              work
            </v-icon>
            Detalle
          </v-btn>
          <v-btn
            :href="props.item.report_file"
            target="_blank"
            :disabled="!props.item.report_file"
            color="success"
          >
            <v-icon left>
              cloud_download
            </v-icon>
            Informe
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <v-layout
      v-if="applications.length > 0"
      justify-center
      mt-3
    >
      <v-flex xs4>
        <div class="text-xs-center">
          <v-pagination
            v-model="page"
            :length="pages"
            total-visible="5"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'CandidateApplications',
  mixins: [JWTConfigMixin, OHCStateMixin],
  data() {
    return {
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
      },
      page: 1,
      pages: 1,
      nextPage: '',
      prevPage: '',
      applications: [],
      headers: [
        {
          text: 'Empresa',
        },
        {
          text: 'Cargo',
        },
        {
          text: 'Analista',
        },
        {
          text: 'Estado',
        },
        {
          text: 'Estado del Proceso',
        },
        {
          text: 'Acciones',
        },
      ],
    };
  },
  watch: {
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        pageEndpoint = `${this.$store.state.endpoints.candidates.applications(this.$route.params.uuid)}
        ?page=${newVal}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadApplications();
    });
  },
  methods: {
    loadApplications() {
      const endpoint = this.$store.state.endpoints.candidates.applications(this.$route.params.uuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.applications = response.data.results;
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.setPaginator();
        });
    },
    setPaginator() {
      this.pagination.page = this.page;
      this.pagination.rowsPerPage = this.applications.length;
    },
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.applications = response.data.results;
          this.$store.commit('unsetLoading');
          this.setPaginator();
        });
    },
  },
};
</script>
